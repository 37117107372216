<script setup lang="ts">
import { randomUUID } from 'uncrypto'
import { useFileUploadWithProgress } from '~/composables/fileUpload'

const props = defineProps<{
  id?: string
  name?: string
  files?: Record<string, string>[]
  uploading?: boolean
  fileSizeLimit?: number
}>()

const emit = defineEmits<{
  (event: 'update:uploading', isUploading: boolean): void
  (event: 'update:files', uploadedFiles: Record<string, string>[]): void
}>()

interface FileObject {
  id: ReturnType<typeof randomUUID>
  type: string
  file: File
  preview: string
  status: 'uploading' | 'completed' | 'error'
}

const { t } = useI18n()
const { handleFileUpload, uploadProgress, cancelUpload } = useFileUploadWithProgress()

const currentFiles = ref<FileObject[]>([])
const fileInputRef = ref<HTMLInputElement | null>(null)
const aggregatedFiles = ref<Record<string, Record<string, string>>>({})

const isUploading = computed(() => Object.keys(uploadProgress).length > 0)
const fileSizeLimit = computed(() => props.fileSizeLimit ?? 20971520) // 20MB

function updateFileStatus(id: string, status: FileObject['status']) {
  const fileToUpdate = currentFiles.value.find(f => f.id === id)
  if (fileToUpdate)
    fileToUpdate.status = status
}

async function onFileUpload(event: InputEvent) {
  const files = (event.target as HTMLInputElement)?.files ?? []

  if (!files?.length)
    return

  // files size validation
  for (const file of Array.from(files)) {
    if (file.size > fileSizeLimit.value)
      throw new Error('File size limit exceeded')
  }

  const uploadPromises = Array.from(files).map(async (file) => {
    const id = randomUUID()
    const type = file.type.split('/')[0]
    const preview = await useFilePreview().create(file)

    const fileObject = { id, type, file, preview, status: 'uploading' as const }

    currentFiles.value.push(fileObject)

    try {
      const result = await handleFileUpload([file], id)

      if (result?.data?.createAssets?.length) {
        const [asset] = result.data.createAssets
        if ('errorCode' in asset) {
          updateFileStatus(id, 'error')
          throw new Error(asset.message)
        }

        aggregatedFiles.value[id] = asset
        emit('update:files', Object.values(aggregatedFiles.value))
        updateFileStatus(id, 'completed')
      }
    }
    catch (err) {
      updateFileStatus(id, 'error')
      console.error(err)
    }
  })

  try {
    await Promise.all(uploadPromises)
  }
  catch (err) {
    console.error(err)
  }

  // Reset the input for next time
  if (fileInputRef.value)
    fileInputRef.value.value = ''
}

function onCancel(index: number) {
  const file = currentFiles.value[index]
  if (file?.id) {
    cancelUpload(file.id)
    delete aggregatedFiles.value[file.id]
    currentFiles.value.splice(index, 1)
    emit('update:files', Object.values(aggregatedFiles.value))
  }
}

watch(isUploading, isUploading => emit('update:uploading', isUploading))
</script>

<template>
  <div class="box-border w-full flex flex-nowrap items-center justify-start">
    <input
      :id="props.id"
      ref="fileInputRef"
      :name="props.name"
      class="hidden"
      type="file"
      multiple
      accept="image/*,video/*"
      @change="onFileUpload"
    >

    <div
      :aria-label="t('aria.upload_attachment')"
      class="relative z-0 mb0 ml0 mr-3 mt0 box-border h12 min-h-0 min-w-0 w12 flex shrink-0 grow-0 basis-auto flex-row cursor-pointer touch-manipulation select-none list-none items-center justify-center border-0 border-slate-500 rounded-2 border-solid bg-slate-500/7 pb0 pl0 pr0 pt-0 no-underline outline-none outline-none hover:no-underline"
      role="button"
      tabindex="0"
      @click="fileInputRef?.click()"
    >
      <NIcon icon="i-heroicons:plus-circle-20-solid" n="xl" />
      <div class="pointer-events-none absolute bottom-0 left-0 right-0 top-0 op0 transition-opacity duration-50 ease-in-out hover:bg-slate-800" />
    </div>

    <div
      v-for="(file, index) in currentFiles" :key="index"
      class="relative mb1.5 ml0 mr-3 mt-[2px] h12 w12 shrink-0 grow-0"
    >
      <div class="relative h12 w12 overflow-x-hidden overflow-y-hidden rounded-2">
        <img class="inline-block h-full w-full object-cover" :src="file.preview" alt="">

        <template v-if="file.type === 'video'">
          <div class="absolute left-1/2 top-1/2 -ml-[12px] -mt-[12px]">
            <div class="rounded-full bg-black/60">
              <NIcon icon="i-heroicons:play-circle" n="xl white" class="text-context" />
            </div>
          </div>
        </template>

        <svg
          v-if="file.id in uploadProgress"
          aria-hidden="true"
          class="absolute bottom-0 left-0 h-[4px] w-full"
          preserveAspectRatio="none"
          viewBox="0 0 100 4"
        >
          <path d="M 0,2 L 100,2" stroke="rgba(0,0,0,.5)" stroke-linecap="round" stroke-width="4" />
          <path
            class="stroke-orange-500"
            d="M 0,2 L 100,2" stroke-linecap="round"
            stroke-width="4"
            style="stroke-dasharray: 100; stroke-dashoffset: 100; transition: stroke-dashoffset 0.5s ease 0s, stroke 0.5s ease 0s;"
            :style="{ strokeDashoffset: `${uploadProgress[file.id] != null ? 100 - uploadProgress[file.id] : 100}` }"
          />
        </svg>
      </div>

      <div class="absolute -right-[8px] -top-[8px]">
        <div class="relative z-0 box-border">
          <div
            :aria-label="t('aria.remove_attachment')"
            class="relative m0 box-border h6 w6 flex cursor-pointer touch-manipulation select-none list-none items-center justify-center border-0 rounded-6 bg-slate-50 p0 text-slate-900 shadow-sm outline-none hover:no-underline"
            role="button"
            tabindex="0"
            @click="onCancel(index)"
          >
            <NIcon icon="i-heroicons:x-mark-20-solid" n="base" />
            <div class="pointer-events-none absolute bottom-0 left-0 right-0 top-0 rounded-1/2 op0 transition-opacity duration-50 ease-in-out" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
