export function useFilePreview() {
  const create = async (file: File): Promise<string> => {
    const fileType = file.type.split('/')[0]

    const readFile = async (readAs: 'readAsDataURL' | 'readAsArrayBuffer') => new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = () => resolve(fileReader.result)
      fileReader.onerror = () => reject(new Error('Unable to read file'))
      fileReader[readAs](file)
    })

    if (fileType === 'image') {
      return await readFile('readAsDataURL') as Promise<string>
    }
    else if (fileType === 'video') {
      const buffer = await readFile('readAsArrayBuffer')
      const blob = new Blob([buffer as BlobPart], { type: file.type })
      const url = URL.createObjectURL(blob)
      const video = document.createElement('video')

      return new Promise<string>((resolve) => {
        const snapImage = () => {
          const canvas = document.createElement('canvas')

          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)

          const image = canvas.toDataURL()

          if (image.length > 100000) {
            URL.revokeObjectURL(url)
            resolve(image)
            return true
          }

          return false
        }

        const timeupdate = () => snapImage() && cleanup()

        function cleanup() {
          video.removeEventListener('timeupdate', timeupdate)
          video.pause()
          video.src = ''
        }

        video.addEventListener('loadeddata', () => snapImage() && cleanup())
        video.addEventListener('timeupdate', timeupdate)
        video.preload = 'metadata'
        video.src = url
        video.muted = true
        video.playsInline = true
        video.play()
      })
    }
    else {
      throw new Error('Unsupported file type')
    }
  }

  return { create }
}
