import { randomUUID } from 'uncrypto'
import type { CreateAssetsMutation } from '#graphql-operations'

export function useFileUploadWithProgress() {
  const _headers = useRequestHeaders(['cookie'])

  const { locale, locales } = useI18n()
  const { customerToken } = useSupportAgent()

  const uploadProgress = reactive<Record<string, number>>({})
  const uploadCancellers = reactive<Record<string, (...args: any[]) => void>>({})

  const handleFileUpload = (files: FileList | File[], id: string) => {
    const currency = locales.value.find(l => l.code === locale)?.currency ?? 'USD'

    if (!files?.length)
      throw new Error('No files to upload')

    return new Promise<{ data: CreateAssetsMutation }>((resolve, reject) => {
      const formData = new FormData()
      const inputFileMap: Record<string, any> = {}

      const operations = {
        operationName: 'createAssets',
        variables: { input: Array.from({ length: files.length }, () => ({ file: null })) },
        query: `mutation createAssets($input: [CreateAssetInput!]!) {
  createAssets(input: $input) {
    ...Asset
    ... on ErrorResult { errorCode message }
  }
}

fragment Asset on Asset {
  id
  preview
}`,
      }

      for (let i = 0; i < files.length; i++) {
        const fileKey = (i + 1).toString()
        inputFileMap[fileKey] = [`variables.input.${i}.file`]
      }

      formData.append('operations', JSON.stringify(operations))
      formData.append('map', JSON.stringify(inputFileMap))

      Array.from(files).forEach((file, i) => formData.append((i + 1).toString(), file))

      const xhr = new XMLHttpRequest()
      xhr.open('POST', '/api/createAssets' + `?languageCode=${locale.value}&currencyCode=${currency}`, true)
      xhr.setRequestHeader('Content-Language', locale.value)
      xhr.setRequestHeader('Content-Currency', currency)
      xhr.setRequestHeader('X-Request-Id', randomUUID())

      if (customerToken.value)
        xhr.setRequestHeader('X-Customer-Token', customerToken.value)

      uploadCancellers[id] = () => xhr.abort()

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable)
          uploadProgress[id] = (event.loaded / event.total) * 100
      })

      xhr.addEventListener('load', () => {
        if (xhr.status === 200) {
          delete uploadProgress[id]
          delete uploadCancellers[id]
          resolve(JSON.parse(xhr.responseText))
        }
        else {
          reject(xhr.statusText)
        }
      })

      xhr.addEventListener('error', () => {
        reject(new Error('Upload failed'))
      })

      xhr.addEventListener('abort', () => {
        delete uploadProgress[id]
        delete uploadCancellers[id]
        resolve(null)
      })

      xhr.send(formData)
    })
  }

  const cancelUpload = (id: string) => {
    uploadCancellers[id]?.()
  }

  return {
    handleFileUpload,
    uploadProgress,
    cancelUpload,
  }
}
